// extracted by mini-css-extract-plugin
export var alertRed = "Form-module--alert-red--4f177";
export var bgBarGrey = "Form-module--bg-bar-grey--f24be";
export var bgBeige = "Form-module--bg-beige--a8c72";
export var bgBeige2 = "Form-module--bg-beige2--958cf";
export var bgCopper = "Form-module--bg-copper--b9662";
export var bgDaffodil = "Form-module--bg-daffodil--7985f";
export var bgGreyText = "Form-module--bg-grey-text--6cec8";
export var bgMing = "Form-module--bg-ming--c93b6";
export var bgMint = "Form-module--bg-mint--73007";
export var bgRed = "Form-module--bg-red--c63a8";
export var bgRedTrans = "Form-module--bg-red-trans--3a7c1";
export var bgSand = "Form-module--bg-sand--5f593";
export var bgSoftSun = "Form-module--bg-softSun--a9066";
export var bgTeal = "Form-module--bg-teal--2c3c5";
export var bgWhite = "Form-module--bg-white--7f905";
export var bgWomenSharp = "Form-module--bg-women-sharp--1f0f1";
export var blackText = "Form-module--black-text--d0295";
export var borderMing = "Form-module--border-ming--a448a";
export var compact = "Form-module--compact--6f653";
export var copper = "Form-module--copper--29163";
export var corners = "Form-module--corners--8ae44";
export var countryCode = "Form-module--countryCode--f8d7d";
export var cursorNormal = "Form-module--cursor-normal--9a0dc";
export var cursorPointer = "Form-module--cursor-pointer--778ec";
export var customMarkdown = "Form-module--customMarkdown--9f606";
export var dUnset = "Form-module--d-unset--9fabe";
export var darkGrey = "Form-module--dark-grey--6d987";
export var dash = "Form-module--dash--fff2f";
export var dashBig = "Form-module--dashBig--187f4";
export var deadSalmon = "Form-module--dead-salmon--30ad2";
export var error = "Form-module--error--9fc4a";
export var filled = "Form-module--filled--4f9b2";
export var flex = "Form-module--flex--1bc53";
export var flex1 = "Form-module--flex-1--2f5bf";
export var fontSize12 = "Form-module--font-size-12--5e891";
export var fontSize20 = "Form-module--font-size-20--2f9c7";
export var formSelect = "Form-module--formSelect--515f0";
export var greyPlaceholder = "Form-module--grey-placeholder--1adae";
export var greyText = "Form-module--grey-text--493cd";
export var h1sizing = "Form-module--h1sizing--cada9";
export var h2sizing = "Form-module--h2sizing--b1fd6";
export var hidden = "Form-module--hidden--5501e";
export var imageAni = "Form-module--imageAni--1cbf5";
export var imageZoom = "Form-module--imageZoom--4c8b7";
export var imageZoomGatsby = "Form-module--imageZoomGatsby--3dedd";
export var infoRow = "Form-module--infoRow--ae3af";
export var italic = "Form-module--italic--2dec4";
export var lightGrey = "Form-module--light-grey--32109";
export var logo = "Form-module--logo--deeba";
export var lora = "Form-module--lora--ed0bb";
export var loraBold = "Form-module--lora-Bold--cdfc5";
export var loraBoldItalic = "Form-module--lora-BoldItalic--c7f40";
export var loraMedium = "Form-module--lora-Medium--aff20";
export var loraSemiBold = "Form-module--lora-SemiBold--1a5f5";
export var main = "Form-module--main--671a6";
export var ming = "Form-module--ming--b4a60";
export var mingHover2 = "Form-module--ming-hover-2--30025";
export var modalOpen = "Form-module--modal-open--60278";
export var noUnderline = "Form-module--no-underline--1a461";
export var openSans = "Form-module--openSans--29c98";
export var openSansBold = "Form-module--openSans-Bold--04b58";
export var openSansSemiBold = "Form-module--openSans-SemiBold--e545a";
export var pageGuide = "Form-module--page-guide--3d052";
export var phone = "Form-module--phone--5369e";
export var popUp = "Form-module--popUp--87a71";
export var popUpAni = "Form-module--pop-up-ani--86a80";
export var quotation = "Form-module--quotation--562d5";
export var quotationform = "Form-module--quotationform--65405";
export var round = "Form-module--round--b3fb5";
export var roundCorners = "Form-module--round-corners--d9070";
export var softCorners = "Form-module--soft-corners--23368";
export var softShadows = "Form-module--soft-shadows--69b4d";
export var softerCorners = "Form-module--softer-corners--bc14f";
export var softyCorners = "Form-module--softy-corners--c6998";
export var tableShadow = "Form-module--table-shadow--cf06c";
export var teal = "Form-module--teal--18fc8";
export var topNavShadow = "Form-module--top-nav-shadow--dc98b";
export var topicArea = "Form-module--topicArea--37d75";
export var uppercase = "Form-module--uppercase--ee5e0";
export var valid = "Form-module--valid--c9ce9";
export var wFull = "Form-module--w-full--f8338";
export var white = "Form-module--white--79893";
export var womenRed = "Form-module--women-red--6eec6";
export var womenSharp = "Form-module--women-sharp--dd440";