// extracted by mini-css-extract-plugin
export var alertRed = "FaqInPage-module--alert-red--77842";
export var bgBarGrey = "FaqInPage-module--bg-bar-grey--7311e";
export var bgBeige = "FaqInPage-module--bg-beige--b6f0f";
export var bgBeige2 = "FaqInPage-module--bg-beige2--50906";
export var bgCopper = "FaqInPage-module--bg-copper--0be10";
export var bgDaffodil = "FaqInPage-module--bg-daffodil--30422";
export var bgGreyText = "FaqInPage-module--bg-grey-text--425e6";
export var bgMing = "FaqInPage-module--bg-ming--27f90";
export var bgMint = "FaqInPage-module--bg-mint--4baaf";
export var bgRed = "FaqInPage-module--bg-red--7a30c";
export var bgRedTrans = "FaqInPage-module--bg-red-trans--b24b0";
export var bgSand = "FaqInPage-module--bg-sand--42789";
export var bgSoftSun = "FaqInPage-module--bg-softSun--eb6e1";
export var bgTeal = "FaqInPage-module--bg-teal--997dd";
export var bgWhite = "FaqInPage-module--bg-white--6ae12";
export var bgWomenSharp = "FaqInPage-module--bg-women-sharp--9b6ba";
export var blackText = "FaqInPage-module--black-text--3fa13";
export var borderMing = "FaqInPage-module--border-ming--efe39";
export var brand = "FaqInPage-module--brand--8da5e";
export var context = "FaqInPage-module--context--f7ccb";
export var copper = "FaqInPage-module--copper--63d3e";
export var corners = "FaqInPage-module--corners--c15ce";
export var cursorNormal = "FaqInPage-module--cursor-normal--cfdb3";
export var cursorPointer = "FaqInPage-module--cursor-pointer--f3222";
export var customMarkdown = "FaqInPage-module--customMarkdown--cb137";
export var dUnset = "FaqInPage-module--d-unset--b509d";
export var darkGrey = "FaqInPage-module--dark-grey--46cd3";
export var dash = "FaqInPage-module--dash--4694c";
export var dashBig = "FaqInPage-module--dashBig--5fec2";
export var deadSalmon = "FaqInPage-module--dead-salmon--d8b27";
export var faqInPage = "FaqInPage-module--faqInPage--4c012";
export var flex = "FaqInPage-module--flex--d609d";
export var flex1 = "FaqInPage-module--flex-1--4b63b";
export var fontSize12 = "FaqInPage-module--font-size-12--92e1b";
export var fontSize20 = "FaqInPage-module--font-size-20--39f21";
export var greyPlaceholder = "FaqInPage-module--grey-placeholder--decce";
export var greyText = "FaqInPage-module--grey-text--9d75d";
export var h1sizing = "FaqInPage-module--h1sizing--8c348";
export var h2sizing = "FaqInPage-module--h2sizing--11e4e";
export var hidden = "FaqInPage-module--hidden--ed5d4";
export var imageAni = "FaqInPage-module--imageAni--58523";
export var imageZoom = "FaqInPage-module--imageZoom--5072f";
export var imageZoomGatsby = "FaqInPage-module--imageZoomGatsby--ef5e0";
export var italic = "FaqInPage-module--italic--b4ba2";
export var lightGrey = "FaqInPage-module--light-grey--ad9a6";
export var logo = "FaqInPage-module--logo--b01d0";
export var lora = "FaqInPage-module--lora--d1432";
export var loraBold = "FaqInPage-module--lora-Bold--f8464";
export var loraBoldItalic = "FaqInPage-module--lora-BoldItalic--622c2";
export var loraMedium = "FaqInPage-module--lora-Medium--13c7f";
export var loraSemiBold = "FaqInPage-module--lora-SemiBold--6b914";
export var main = "FaqInPage-module--main--3be1c";
export var ming = "FaqInPage-module--ming--1e833";
export var mingHover2 = "FaqInPage-module--ming-hover-2--15b93";
export var modalOpen = "FaqInPage-module--modal-open--fce1c";
export var noUnderline = "FaqInPage-module--no-underline--7db57";
export var openSans = "FaqInPage-module--openSans--764b2";
export var openSansBold = "FaqInPage-module--openSans-Bold--87ff0";
export var openSansSemiBold = "FaqInPage-module--openSans-SemiBold--95646";
export var pageGuide = "FaqInPage-module--page-guide--20867";
export var popUp = "FaqInPage-module--popUp--b04ff";
export var popUpAni = "FaqInPage-module--pop-up-ani--0fd24";
export var qa = "FaqInPage-module--qa--2b044";
export var question = "FaqInPage-module--question--9d199";
export var quotation = "FaqInPage-module--quotation--c6482";
export var round = "FaqInPage-module--round--49079";
export var roundCorners = "FaqInPage-module--round-corners--c829d";
export var softCorners = "FaqInPage-module--soft-corners--887b1";
export var softShadows = "FaqInPage-module--soft-shadows--7ad94";
export var softerCorners = "FaqInPage-module--softer-corners--080ca";
export var softyCorners = "FaqInPage-module--softy-corners--67932";
export var tableShadow = "FaqInPage-module--table-shadow--62eb7";
export var teal = "FaqInPage-module--teal--17b91";
export var topNavShadow = "FaqInPage-module--top-nav-shadow--22d54";
export var topicArea = "FaqInPage-module--topicArea--84e9b";
export var uppercase = "FaqInPage-module--uppercase--7c8cc";
export var wFull = "FaqInPage-module--w-full--748ef";
export var white = "FaqInPage-module--white--3ffbf";
export var women = "FaqInPage-module--women--4f8f2";
export var womenRed = "FaqInPage-module--women-red--0408e";
export var womenSharp = "FaqInPage-module--women-sharp--0a692";