import "./Featured.scss"

import React from "react"

//import { getArticleCatName } from "../../plugins/common"
import ArticleCard from "../ArticleCard"

// import bg from "../../images/home/sand-bg.webp"

const Featured = ({ props, categories, labels, lang }) => {
  let { title, articles } = props
  const articlesData = articles.map(article => {
    return {
      title: article.title,
      image: article.img ?? article.image,
      slug: article.slug,
      parentSlug: article.altParentHubSlug
        ? article.altParentHubSlug?.slug
        : "resources",
      category:
        categories && categories.length > 0
          ? categories.find(
              category => category.strapiId === article.article_category
            )?.name
          : "",
    }
  })
  // useEffect(() => {
  //   title = props.title
  //   articles = props.articles
  //   if (categories) articles.map(item => getArticleCatName(item, categories))
  // }, [props])

  return (
    <div className="featured container ming">
      <div className="row">
        {articles.length > 0 && (
          <div className="col-12 text-start">
            <h2>{title}</h2>
          </div>
        )}
      </div>
      <div className="row articleFeature">
        <div className="col-lg-4 my-lg-0">
          {articlesData[0] && (
            <ArticleCard
              article={articlesData[0]}
              type="vertical"
              labels={labels}
              lang={lang}
            />
          )}
        </div>
        <div className="col-lg-8 d-flex flex-column">
          <div className="flex-1 horiz">
            {articlesData[1] && (
              <ArticleCard
                article={articlesData[1]}
                type="horizontal"
                labels={labels}
                lang={lang}
              />
            )}
          </div>
          <div className="flex-1 horiz">
            {articlesData[2] && (
              <ArticleCard
                article={articlesData[2]}
                type="horizontal"
                labels={labels}
                lang={lang}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Featured
