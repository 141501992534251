import "./ArticleCard.scss"

import * as React from "react"

import { Link } from "gatsby"

import {
  getBackgroundImage,
  handleExternalUrl,
  updateUrlByLocale,
} from "../plugins/common"
import Button from "./Button"

const ArticleCard = props => {
  const { img, image, category, title, slug, summary, url, parentSlug } =
    props.article
  const lang = props.lang
  const type = props.type ?? "flexible"
  const buttonText =
    props.type === "guide"
      ? props.labels?.browse.getGuideLabel
      : props.labels?.browse.readmoreLabel
  const buttonType = props.type === "guide" ? "getGuide" : "readMore"
  //card type
  //1.      vertical
  //2.      horizontal
  //3.      flexible
  //4.      guide
  //5.      healthymatters

  //img:    external image
  //image:  static gatsby image

  //slug:   internal link
  //url:    external link
  //console.log(props.article)
  //console.log("url slug", url, slug)
  return (
    <>
      {slug && (
        <Link
          className="acLink"
          to={updateUrlByLocale(
            `/${parentSlug ? parentSlug : "resources"}/` + slug,
            lang
          )}
        >
          <div className={`articleCard soft-shadows soft-corners ${type}`}>
            <div
              className="cover"
              style={{
                backgroundImage: `url(${getBackgroundImage(img ?? image)})`,
              }}
            ></div>
            <div className="details">
              <span className="category">{category}</span>
              <h3 className="title ming">{title}</h3>
              {props.type !== "horizontal" && (
                <p className="summary">{summary}</p>
              )}
              <Button
                lang={lang}
                type={buttonType}
                text={buttonText}
                // url={`/resources/${slug}`}
                // externalUrl={url}
              />
            </div>
          </div>
        </Link>
      )}
      {url && (
        <a
          className="acLink"
          href={handleExternalUrl(url)}
          target="_blank"
          rel="noreferrer"
        >
          <div className={`articleCard soft-shadows soft-corners ${type}`}>
            <div
              className="cover"
              style={{
                backgroundImage: `url(${getBackgroundImage(img ?? image)})`,
              }}
            ></div>
            <div className="details">
              <span className="category">{category}</span>
              <h3 className="title ming">{title}</h3>
              {props.type !== "horizontal" && (
                <p className="summary">{summary}</p>
              )}
              <Button
                lang={lang}
                type={buttonType}
                text={buttonText}
                // url={`/resources/${slug}`}
                // externalUrl={url}
              />
            </div>
          </div>
        </a>
      )}
    </>
  )
}
export default ArticleCard
