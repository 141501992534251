// extracted by mini-css-extract-plugin
export var alertRed = "ContactForm-module--alert-red--266ab";
export var bgBarGrey = "ContactForm-module--bg-bar-grey--f2c27";
export var bgBeige = "ContactForm-module--bg-beige--e0951";
export var bgBeige2 = "ContactForm-module--bg-beige2--1fd51";
export var bgCopper = "ContactForm-module--bg-copper--59b3b";
export var bgDaffodil = "ContactForm-module--bg-daffodil--b83f8";
export var bgGreyText = "ContactForm-module--bg-grey-text--14e56";
export var bgMing = "ContactForm-module--bg-ming--c120c";
export var bgMint = "ContactForm-module--bg-mint--e8803";
export var bgRed = "ContactForm-module--bg-red--7d6f4";
export var bgRedTrans = "ContactForm-module--bg-red-trans--6899d";
export var bgSand = "ContactForm-module--bg-sand--e4e6c";
export var bgSoftSun = "ContactForm-module--bg-softSun--c6b8e";
export var bgTeal = "ContactForm-module--bg-teal--25bc2";
export var bgWhite = "ContactForm-module--bg-white--a8598";
export var bgWomenSharp = "ContactForm-module--bg-women-sharp--24a29";
export var blackText = "ContactForm-module--black-text--81b50";
export var borderMing = "ContactForm-module--border-ming--d9c4b";
export var contactForm = "ContactForm-module--contactForm--bd716";
export var copper = "ContactForm-module--copper--6e47e";
export var corners = "ContactForm-module--corners--f6cee";
export var cursorNormal = "ContactForm-module--cursor-normal--78597";
export var cursorPointer = "ContactForm-module--cursor-pointer--a942c";
export var customMarkdown = "ContactForm-module--customMarkdown--15417";
export var dUnset = "ContactForm-module--d-unset--0bf71";
export var darkGrey = "ContactForm-module--dark-grey--5939f";
export var dash = "ContactForm-module--dash--7579f";
export var dashBig = "ContactForm-module--dashBig--93c7d";
export var deadSalmon = "ContactForm-module--dead-salmon--c7ce5";
export var flex = "ContactForm-module--flex--e9b6f";
export var flex1 = "ContactForm-module--flex-1--ba1ff";
export var fontSize12 = "ContactForm-module--font-size-12--675f1";
export var fontSize20 = "ContactForm-module--font-size-20--e7fc9";
export var formWrapper = "ContactForm-module--formWrapper--5d8d0";
export var greyPlaceholder = "ContactForm-module--grey-placeholder--279ce";
export var greyText = "ContactForm-module--grey-text--1b0ce";
export var h1sizing = "ContactForm-module--h1sizing--07383";
export var h2sizing = "ContactForm-module--h2sizing--8fcd9";
export var hidden = "ContactForm-module--hidden--9d043";
export var imageAni = "ContactForm-module--imageAni--19ee7";
export var imageZoom = "ContactForm-module--imageZoom--98680";
export var imageZoomGatsby = "ContactForm-module--imageZoomGatsby--359e9";
export var italic = "ContactForm-module--italic--f8aa4";
export var lightGrey = "ContactForm-module--light-grey--4af5e";
export var logo = "ContactForm-module--logo--b748f";
export var lora = "ContactForm-module--lora--0d06c";
export var loraBold = "ContactForm-module--lora-Bold--504db";
export var loraBoldItalic = "ContactForm-module--lora-BoldItalic--51473";
export var loraMedium = "ContactForm-module--lora-Medium--0480a";
export var loraSemiBold = "ContactForm-module--lora-SemiBold--55bba";
export var main = "ContactForm-module--main--7be6d";
export var ming = "ContactForm-module--ming--ddeb7";
export var mingHover2 = "ContactForm-module--ming-hover-2--c83aa";
export var modalOpen = "ContactForm-module--modal-open--6690e";
export var noUnderline = "ContactForm-module--no-underline--89ed5";
export var openSans = "ContactForm-module--openSans--5e761";
export var openSansBold = "ContactForm-module--openSans-Bold--f3552";
export var openSansSemiBold = "ContactForm-module--openSans-SemiBold--5ea10";
export var pageGuide = "ContactForm-module--page-guide--2a1d4";
export var popUp = "ContactForm-module--popUp--72c3f";
export var popUpAni = "ContactForm-module--pop-up-ani--d4148";
export var quotation = "ContactForm-module--quotation--e5de7";
export var round = "ContactForm-module--round--46c7f";
export var roundCorners = "ContactForm-module--round-corners--e4f87";
export var softCorners = "ContactForm-module--soft-corners--904bb";
export var softShadows = "ContactForm-module--soft-shadows--e4d54";
export var softerCorners = "ContactForm-module--softer-corners--db5b7";
export var softyCorners = "ContactForm-module--softy-corners--b5aba";
export var tableShadow = "ContactForm-module--table-shadow--48480";
export var teal = "ContactForm-module--teal--b1b28";
export var thanksMsg = "ContactForm-module--thanksMsg--8f466";
export var topNavShadow = "ContactForm-module--top-nav-shadow--95439";
export var topicArea = "ContactForm-module--topicArea--11b46";
export var uppercase = "ContactForm-module--uppercase--e9dba";
export var wFull = "ContactForm-module--w-full--40fe3";
export var white = "ContactForm-module--white--c8424";
export var womenRed = "ContactForm-module--women-red--54356";
export var womenSharp = "ContactForm-module--women-sharp--9c9af";