import { Link } from "gatsby"
import React from "react"
import { updateUrlByLocale } from "../../plugins/common"

const BreadCrumb = ({ paths, rootLabel, locale, hideHome = false }) => {
  return (
    <div className={`label-16 leading-[1.5]`}>
      {!hideHome && (
        <span>
          <Link
            to={updateUrlByLocale("/", locale)}
            className={`no-underline inline-flex tw-pr-1`}
          >
            {rootLabel}
          </Link>{" "}
          &gt;{" "}
        </span>
      )}
      {paths && paths.length > 0 && (
        <span>
          {paths.map(
            (path, index) =>
              path[0]?.name && (
                <span key={index} className={`text-text-40`}>
                  {index !== 0 &&
                    `${" "}
                  >${" "}`}
                  {path.map((p, index) => (
                    <span key={index}>
                      {index > 0 ? " / " : ""}
                      {p.url ? (
                        <Link key={index} to={p.url} className={`no-underline tw-pl-1`}>
                          {p.name}
                        </Link>
                      ) : (
                        <span
                          key={index}
                          className={`text-primary-80 inline-flex`}
                        >
                          {p.name}
                        </span>
                      )}
                    </span>
                  ))}
                </span>
              )
          )}
        </span>
      )}
    </div>
  )
}

export default BreadCrumb
